<template>
  <div>
    <div class="lg:flex grid gap-3 mb-8">
      <Button :label="google_label" :icon="Google" @click="triggerAuth('gmail')" outline color="black" full />
      <Button :label="github_label" :icon="Github" @click="triggerAuth('github')" color="black" full />
      <Button :label="linkedin_label" :icon="Linkedin" @click="linkedinAuth" :loading="loading" color="info" full />
    </div>
    <div class="flex items-center mb-8">
      <div class="flex w-full bg-brand-black/10 h-0.5"></div>
      <p class="text-brand-black/40 px-3">OR</p>
      <div class="flex w-full bg-brand-black/10 h-0.5"></div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { getAuth } from 'firebase/auth';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Button from '@/components/Button.vue';
import { Google, Linkedin, Github } from '@/utils/icons';
import { socialLogin, alert } from '@/utils/helper';

const props = defineProps({
  action_type: {
    type: String,
    required: true,
  },
});

const route = useRoute();

const store = useStore();

const emits = defineEmits(['auth-fail', 'auth-success']);

const loading = ref(false);

const user = ref({
  email: '',
  password: '',
  type: 'candidate',
  auth_type: '',
  auth_type_id: '',
});

const linkedinState = ref('b77aea2f-eb72-4cad-98da-cb18d78c2b23');
const linkedinClientId = ref(process.env.VUE_APP_LINKEDIN_CLIENTID);
const linkedinClientSecret = ref(process.env.VUE_APP_LINKEDIN_CLIENT_SECRET);
const linkedinRedirectUri = ref(process.env.VUE_APP_BASE_URL + '/');

const encodeLinkedInCallBack = computed(() => {
  const relativeRoute = props.action_type == 'login' ? 'login' : 'register';
  return encodeURIComponent(linkedinRedirectUri.value + relativeRoute);
});

const action_text = props.action_type === 'login' ? 'Log in' : 'Sign Up';

const github_label = computed(() => `${action_text} with Github`);
const google_label = computed(() => `${action_text} with Google`);
const linkedin_label = computed(() => `${action_text} with LinkedIn`);

const triggerAuth = (type) => googleGithubAuth(type);

const linkedinAuth = () => {
  const base = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedinClientId.value}`;
  const url = `${base}&redirect_uri=${encodeLinkedInCallBack.value}&state=${linkedinState.value}&scope=r_liteprofile%20r_emailaddress`;
  window.location.href = url;
};

const populateUserData = async (user_data) => {
  const { displayName, email, uid, accessToken } = user_data;
  if (props.action_type === 'login') {
    user.value = {
      password: 'password',
      type: 'candidate',
      email,
      auth_type_id: uid,
      social_media_access_key: accessToken,
    };
  }
  if (props.action_type === 'signup') {
    const userInfo = displayName.split(' ');

    user.value = {
      first_name: userInfo[0] || 'FirstName',
      last_name: userInfo[1] || 'LastName',
      password: uid,
      password_confirmation: uid,
      registration_type: 'candidate',
      email,
      auth_type_id: uid,
      social_media_access_key: accessToken,
    };
  }
  return true;
};

const googleGithubAuth = async (auth_type) => {
  try {
    const result = await socialLogin(auth_type);
    // The signed-in user info.
    const auth = getAuth();
    const userData = result.user || auth.currentUser;
    if (!userData?.email) {
      alert('User information not found!', 'error');
      emits('auth-fail');
    } else {
      const { uid } = userData;
      const { data } = await store.dispatch('auth/firebaseAccessToken', {
        uid,
      });
      process.env.NODE_ENV === 'development' ? console.log(data) : '';
      await populateUserData(userData);
      user.value.auth_type = auth_type;
      user.value.id_token = data?.id_token;
      emits('auth-success', user.value);
    }
  } catch (error) {
    let { message } = error;
    message = message.includes('account-exists-with-different-credential')
      ? 'You have multiple social logins with the same email. Please link them and try again'
      : message;
    alert(message, 'error');
    emits('auth-fail');
  }
};

onMounted(async () => {
  const params = route.query;
  const redirect_uri = linkedinRedirectUri.value + (props.action_type == 'login' ? 'login' : 'register');

  if (linkedinState.value == params?.state) {
    const fd = new FormData();
    fd.append('code', params.code);
    fd.append('grant_type', 'authorization_code');
    fd.append('client_id', linkedinClientId.value);
    fd.append('client_secret', linkedinClientSecret.value);
    fd.append('redirect_uri', redirect_uri);

    try {
      //Get access token
      loading.value = true;
      const res = await store.dispatch('auth/linkedAccessToken', { code: params.code, redirect_uri });
      user.value = { ...res.data, auth_type: 'linkedin' };

      if (props.action_type === 'login') {
        const { first_name, last_name, password_confirmation, registration_type, ...loginUser } = user.value;
        user.value = {
          ...loginUser,
          type: registration_type,
        };
      }

      emits('auth-success', user.value);
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  }
});
</script>
