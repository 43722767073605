<template>
  <section class="lg:px-20 lg:py-11 p-4 bg-white rounded-xl w-">
    <div class="mb-10">
      <p class="text-brand-black text-[40px] font-albertExtraBold">Sign Up</p>
      <p class="text-brand-black/70 text-lg font-normal">
        Have an account? <router-link to="/login" data-test="logintext" class="text-brand-primary font-albertBold text-lg">Log In</router-link>
      </p>
    </div>
    <social-auth data-test="socialauth" action_type="signup" @auth-success="initAuth" />

    <form @submit.prevent="handleRegistration">
      <div class="grid gap-4 mb-6 md:grid-cols-2">
        <div>
          <Input
            data-test="first_name"
            :isRequired="true"
            label="First Name"
            id="first_name"
            type="text"
            labelFor="first_name"
            placeholder="John"
            v-model="v$.first_name.$model"
            :minlength="3"
          />
          <div class="text-sm whitespace-nowrap text-red-500" v-for="(error, index) in v$.first_name.$errors" :key="index">
            {{ error.$message }}
          </div>
        </div>

        <div class="relative">
          <Input
            data-test="last_name"
            :isRequired="true"
            label="Last Name"
            id="last_name"
            type="text"
            labelFor="last_name"
            placeholder="Doe"
            v-model="v$.last_name.$model"
            :minlength="3"
          />
          <div class="text-sm whitespace-nowrap text-red-500" v-for="(error, index) in v$.last_name.$errors" :key="index">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="relative mb-6">
        <Input
          data-test="email"
          :isRequired="true"
          label="Email Address"
          id="email"
          type="email"
          labelFor="email"
          placeholder="e.g example@email.com"
          v-model="v$.email.$model"
        />
        <div class="text-sm whitespace-nowrap text-red-500" v-for="(error, index) in v$.email.$errors" :key="index">
          {{ error.$message }}
        </div>
      </div>

      <div class="relative mb-6">
        <Input
          data-test="password"
          :isRequired="true"
          label="Create Password"
          id="password"
          :type="showPassword ? 'text' : 'password'"
          labelFor="password"
          placeholder="********"
          v-model="v$.password.$model"
        />
        <div class="flex absolute inset-y-0 right-0 items-center pr-3 cursor-pointer mt-10">
          <EyeClose v-if="!showPassword" @click="showPassword = true" />
          <Email v-else @click="showPassword = false" />
        </div>
        <ul data-test="password-errors" class="list-disc text-sm whitespace-nowrap text-red-500">
          <li v-for="error in v$.password.$errors" :key="error.$message">{{ error.$message }}</li>
        </ul>
      </div>

      <div class="relative mb-6">
        <Input
          data-test="confirm-password"
          :isRequired="true"
          label="Confirm Password"
          id="confirm_password"
          :type="showConfirmPassword ? 'text' : 'password'"
          labelFor="password"
          placeholder="********"
          v-model="v$.password_confirmation.$model"
        />
        <div class="flex absolute inset-y-0 right-0 items-center pr-3 cursor-pointer mt-10">
          <EyeClose v-if="!showConfirmPassword" @click="showConfirmPassword = true" />
          <Email v-else @click="showConfirmPassword = false" />
        </div>
        <ul data-test="password-confirmation-errors" class="list-disc text-sm whitespace-nowrap text-red-500 mb-4">
          <li v-for="error in v$.password_confirmation.$errors" :key="error.$message">{{ error.$message }}</li>
        </ul>
      </div>
      <Button data-test="signup-button" label="Sign Up" color="primary" full type="submit" :loading="loading" :disabled="disabled" />

      <div class="lg:flex justify-center mt-10 text-center">
        <label for="remember" class="text-base font-medium text-brand-black"
          >By signing up, you agree to CareerBuddy
          <a data-test="terms-link" :href="terms_link" class="text-brand-sky cursor-pointer hover:underline"
            >Terms of Service and Privacy Policies</a
          ></label
        >
      </div>
    </form>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import SocialAuth from '@/components/SocialAuth.vue';
import Button from '@/components/Button.vue';
import Input from '@/components/form/Input.vue';
import Email from '@/assets/icons/email.svg?inline';
import EyeClose from '@/assets/icons/eye-slash.svg?inline';
import { errorMessage, alert } from '@/utils/helper';

const router = useRouter();
const disabled = ref(false);
const loading = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const store = useStore();
const state = ref({
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_confirmation: '',
  registration_type: 'candidate',
  auth_type: 'email',
  auth_type_id: '',
});
const terms_link = computed(() => `//${process.env.VUE_APP_DOMAIN}/terms-and-privacy`);
const passwordIsSame = (password) => {
  return password === state.value.password;
};

const rules = {
  first_name: {
    required,
    minLength: minLength(2),
  },
  last_name: {
    required,
    minLength: minLength(2),
  },
  email: {
    email,
    required,
  },
  password: {
    required,
    upperCase: helpers.withMessage(
      () => `Include at least one uppercase letter`,
      (value) => /(?=.*[A-Z])/.test(value),
    ),
    minLength: minLength(8),
    lowerCase: helpers.withMessage(
      () => `Include at least one lowercase letter`,
      (value) => /(?=.*[a-z])/.test(value),
    ),
    number: helpers.withMessage(
      () => `Include at least one number`,
      (value) => /(?=.*?[0-9])/.test(value),
    ),
    specialCharacter: helpers.withMessage(
      () => `Include at least a special character`,
      (value) => /(?=.*?[#?!@$%^&*-])/.test(value),
    ),
  },
  password_confirmation: { required, sameAsPassword: helpers.withMessage('Password does not match', passwordIsSame) },
};

const initAuth = (user) => {
  state.value = user;
  handleRegistration();
};

async function handleRegistration() {
  if (!v$.value.$anyDirty && state.value.auth_type == 'email') return;
  try {
    loading.value = true;
    disabled.value = true;
    const { message } = await store.dispatch('auth/register', state.value);
    alert(message, 'success');
    await store.commit('global/setForm', { key: 'email', value: state.value.email });
    return state.value.auth_type == 'email' ? router.push('/register-verify') : router.push('/onboard');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = disabled.value = false;
    state.value.auth_type = 'email';
  }
}

const v$ = useVuelidate(rules, state);
</script>
